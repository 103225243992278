
import { defineComponent } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
// import BaseSearch from '@/components/Base/BaseSearch.vue';
import IconUp from '@/components/Icon/IconArrowUp.vue';
import IconDown from '@/components/Icon/IconArrowDown.vue';
import { historyTransactionPool } from '@/apis/ApiPool';
import SketetonLoader from '@/components/Base/BaseSkeletonLoader.vue';
import { numberToMoney } from '@/utils/index';
import { mapMutations, mapGetters } from 'vuex';
export default defineComponent({
  components: {
    DefaultLayout,
    // BaseSearch,
    IconUp,
    IconDown,
    SketetonLoader,
  },
  data() {
    return {
      loading: false,
      numberToMoney,
    };
  },
  created() {
    const id: any = this.$route.params.id;
    this.fetchHistoryTransaction(id);
  },
  computed: {
    ...mapGetters({
      historyTransaction: 'getHistoryTransaction',
      dataOverview: 'getOverviewPool',
    }),
  },
  methods: {
    ...mapMutations({
      setHistoryTransaction: 'setHistoryTransaction',
    }),
    fetchHistoryTransaction(id: string) {
      this.loading = true;
      historyTransactionPool(id)
        .then((res) => {
          this.setHistoryTransaction(res.data);
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
});
